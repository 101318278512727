$primary: #DB157A !default;
$light: #F7F9FC !default;
$theme-contrast-color: #fff !default;
$theme1: #007FAE;

$border-width: 1px;
$theme-colors: (
        'theme1': $theme1
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$enable-responsive-font-sizes: true;
$btn-font-weight: bold;
$headings-font-weight: 700;
$enable-rounded: true;
$enable-shadows: false;
$link-decoration: underline;
$nav-link-padding-x: .5rem;
$border-radius: 7px;
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;

// import font
@import url("https://use.typekit.net/msj4dbp.css");
$font-family-base: "museo", serif;

@import '@/src/scss/main.scss';

// SCSS Components
@import '@/src/scss/components/mijn-sidebar.scss'; // Mijn omgeving css (sidebar theme)
@import "animations";

h1,h2,h3,h4,h5,h6 {
  text-transform: uppercase;
}

#main-content {
  margin-bottom: 2rem;
}

.logo-placement {
  top: -50px;
}

.shopping-cart-link {
  a {
    @extend .position-relative;
    .badge-pill {
      bottom: 20px;
      right: 0;
      @include media-breakpoint-up(xl) {
        position: absolute;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .main-nav {
    .dropdown-menu {
      background: $primary;
      padding: 0;
      overflow: hidden;
      .sub-dropdown {
        background: lighten($primary, 5%);
        .dropdown-item:hover {
          background: darken($primary, 5%);
        }
      }
      .dropdown-item:hover {
        background: lighten($primary, 5%);
      }
    }
  }
  .main-nav {
    .dropdown-toggle, .dropdown-item {
      color: $white !important;
    }
  }
}


@include media-breakpoint-down(lg) {
  .navbar-collapse {
    background-color: $primary;
    position: relative;
    top: -6px;
    .nav-link, .dropdown-toggle:not(.dropdown-toggle-split) {
      color: $white !important;
      background: $primary;
      font-weight: bold;
      padding: 1rem 1.5rem;
    }
  }

  .dropdown-menu {
    padding: 0;
    float: none !important;
    text-align: center;

  }

  .dropdown-submenu {
    .dropdown-menu {
      border: none;
    }
    a.dropdown-item {
      color: $dark !important;
      background: $white;
    }
    border-bottom: solid 1px #f1f1f1;
  }
  .sub-dropdown {
    a {
      color: $dark !important;
    }
  }
}

.info-bar--background {
  @extend .text-primary;
  @extend .px-0;
}

.container-xl-fluid {
  @include media-breakpoint-up(xl) {
    max-width: unset !important;
    width: 100% !important;
  }
}

.hero-payoff {
  position: absolute;
  left: 14vw;
  top: 12vw;
  transform: rotate(342deg);
  @include media-breakpoint-up(xxl) {
    left: 19vw;
    top: 8vw;
  }
}

.home-intro-wrapper {
  h1 {
    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }
  }
}

.actionbannerline {
  right: 0;
  width: 100vw;
  top: -28rem;
  position: absolute;
  z-index: -9;
  @include media-breakpoint-up(md) {
    top: -9rem;
  }
  @include media-breakpoint-up(lg) {
    top: -18rem;
  }
  @include media-breakpoint-up(xl) {
    top: -15rem;
    width: 105vw;
  }
  @include media-breakpoint-up(xxxl) {
    top: -15rem;
  }
}

.contact-page--main {
  @extend .mt-3;
}

.footer--bottom {
  a, i {
    color: $white;
  }
}

.footer-flavor-1 {
  z-index: -1;
  position: relative;
  bottom: -5px;
  width: 100%;
  height: auto;
}

.footer-flavor-2 {
  position: absolute;
  left: -10vw;
  bottom: -2rem;
  max-width: 350px;
  width: 25vw;
  height: auto;
  @include media-breakpoint-down(xxl) {
    left: calc(-320px + 10vw)
  }
  @include media-breakpoint-down(xl) {
    left: calc(-300px + 10vw)
  }
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.footer-flavor-3 {
  position: absolute;
  right: -12vw;
  bottom: 4rem;
  width: 24vw;
  height: auto;
  max-width: 485px;
  @include media-breakpoint-down(xxl) {
    right: calc(-375px + 10vw);
    bottom: 3rem;
  }
  @include media-breakpoint-down(lg) {
    right: calc(-300px + 10vw);
    bottom: 5rem;
  }
  @include media-breakpoint-down(md)  {
    right: calc(-225px + 10vw);
    bottom: 27rem;
  }
  @include media-breakpoint-down(sm) {
    right: calc(-175px + 10vw);
    bottom: 28rem;

  }
}

.social-icons {
  i {
    color: $primary;
  }
  a:hover {
    i {
      color: darken($primary, 10%);
    }
  }
}

.circle {
  border-radius: 50%;
}

.btn {
  img {
    width: 16px;
    height: 17px;
    position: relative;
    top: -2px;
    margin-left: 6px !important;
    display: inline-block !important;
  }
}

.sloped-card-header {
  position: absolute;
  background: $white;
  width: 100%;
  border-bottom: solid 20px $light;
  border-radius: 0% 162% 130% 58% / 100% 0% 100% 0%;
  @extend .p-3
}

.flavor-border {
  border: 2vw solid white;
  padding: 2vw;
  background: $light;
  @include media-breakpoint-up(lg) {
    border: 18px solid white;
    padding: 18px;
  }
}

.navbar-brand--img {
  width: 80px;
  max-width: 150px;
  @include media-breakpoint-up(sm) {
    width: 100%;
  }
}

.navbar-brand--bg {
  background: #fff;
  border-radius: 50%;
  position: absolute;
  width: 82px;
  left: -1px;
  max-width: 150px;
  top: 37px;
  @include media-breakpoint-up(sm) {
    top: 2px;
    left: -3px;
    width: 100%;
  }
}

.hero-clipped-image {
  min-height: 100%;
  height: 100%;
  width: 100%;
  @include media-breakpoint-up(xl) {
    max-width: 100%;
  }
}

body.home{
  .navbar-brand--bg {
    background: $primary;
  }
}

.drawline01 {
  position: absolute;
  left:50px;
  width: 22vw;
  height: auto;
  top: 55px;
  @include media-breakpoint-up(sm) {
    left: 80px;
  }
  @include media-breakpoint-up(xl) {
    left: 40px;
    top: 0;
  }
}

.drawline02 {
  position: fixed;
  left: 135px;
  width: 55vw;
  top: -300px;
  @include media-breakpoint-up(md) {
    left: 180px;
    top: -255px;
  }
  @include media-breakpoint-up(lg) {
    top: -250px;
  }
  @include media-breakpoint-up(xxl) {
    top: -300px;
  }
}

.drawline03 {
  position: absolute;
  width: 115vw;
  left: -15vw;
  height: auto;
  top: 175px;
  max-width: 100%;
  @include media-breakpoint-up(sm) {
    top: 88px;
  }
  @include media-breakpoint-up(xl) {
    top: 16px;
  }
}

body.home {
  .navbar-brand--img {
    filter: brightness(0) invert(1);
  }
}

.force-dark-nav {
  @include media-breakpoint-up(xl) {
    .nav-item > .nav-link {
      color: $dark !important;
    }
  }
}

ul.navbar-nav > li.nav-item {
  @extend .mr-2
}

.nav-shadow {
  @include media-breakpoint-up(xl) {
    .nav-link {
      text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.40);
    }
  }
}

.main-nav:not(.force-dark-nav) {
  @include media-breakpoint-up(xl) {
    .nav-link, .shopping-cart-link a {
      color: $white !important;
    }
  }
}

.nav-link {
  text-decoration: none;
}

.page-content-wrapper {
  min-height: 80vh;
}

.extra-spacing {
  @extend .mb-4;
}

.login--button {
  @extend .btn-theme1;
}

.background-image-div {
  width: 135%;
  display: block;
  height: 33.3vw;
  max-height: 400px;
  background: $white;
  background-size: cover;

  clip-path: url(#hero-clip);
  @include media-breakpoint-up(xxl) {
    width: 100%;
  }
}

.mid-image-div {
  width: 120%;
  display: block;
  height: 30vw;
  max-height: 365px;
  background: $primary;
  background-size: cover;
  img {
    object-fit: cover;
  }

  clip-path: url(#hero-clip-2);
  @include media-breakpoint-up(xxl) {
    width: 93%;
  }
}

.footer-cta--image {
  @include media-breakpoint-down(sm) {
    width: 100% !important;
    height: auto !important;
  }
}

.sidebar {
  @include media-breakpoint-up(lg) {
    border-bottom-right-radius: 35%;
    border-top-right-radius: 15%;
  }
  @include media-breakpoint-up(xxl) {
    border-bottom-right-radius: 50%;
    border-top-right-radius: 30%;
  }
}

.card-stretch-hover {
  .card-img-top {
    width: 100%;
    transition: transform .3s;
    @extend .h-auto;
  }
  &:hover {
    .card-img-top {
      transform: scale(1.1);
    }
  }
}

.breadcrumb-item {
  a {
    text-decoration: none;
  }
}
