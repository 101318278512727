body.showSplash, body.home {

  /***************************************************
   * Logo
   **************************************************/


  @keyframes animate-svg-stroke-1 {
    0% {
      stroke-dashoffset: 569.4000244140625px;
      stroke-dasharray: 569.4000244140625px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 569.4000244140625px;
    }
  }

  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(0, 0, 0);
    }
  }

  .svg-elem-1 {
    animation: animate-svg-stroke-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0s both,
    animate-svg-fill-1 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }

  @keyframes animate-svg-stroke-2 {
    0% {
      stroke-dashoffset: 1837.6123046875px;
      stroke-dasharray: 1837.6123046875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1837.6123046875px;
    }
  }

  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }

    100% {
      fill: rgb(236, 0, 140);
    }
  }

  .svg-elem-2 {
    animation: animate-svg-stroke-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.12s both,
    animate-svg-fill-2 0.2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }

  @keyframes animate-svg-stroke-3 {
    0% {
      stroke-dashoffset: 447.4778382790327px;
      stroke-dasharray: 447.4778382790327px;
    }

    100% {
      stroke-dashoffset: 894.9556765580654px;
      stroke-dasharray: 447.4778382790327px;
    }
  }

  .svg-elem-3 {
    animation: animate-svg-stroke-3 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s both,
    animate-svg-fill-3 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }

  /***************************************************
   * Drawline01
   **************************************************/

  @keyframes animate-drawline01-stroke-1 {
    0% {
      stroke-dashoffset: 1380.54931640625px;
      stroke-dasharray: 1380.54931640625px;
    }

    100% {
      stroke-dashoffset: 2762px;
      stroke-dasharray: 1380.54931640625px;
    }
  }

  .drawline01-1 {
    animation: animate-drawline01-stroke-1 5s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s both;
  }

  @keyframes animate-drawline01-stroke-2 {
    0% {
      stroke-dashoffset: 275.442138671875px;
      stroke-dasharray: 275.442138671875px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 275.442138671875px;
    }
  }

  .drawline01-2 {
    animation: animate-drawline01-stroke-2 2s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
  }

  /***************************************************
   * Drawline02
   **************************************************/

  @keyframes animate-drawline02-stroke-1 {
    0% {
      stroke-dashoffset: 1743px;
      stroke-dasharray: 1743px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 1743px;
    }
  }

  .drawline02-1 {
    animation: animate-drawline02-stroke-1 7s cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s both;
  }

  @keyframes animate-drawline02-stroke-2 {
    0% {
      stroke-dashoffset: 972px;
      stroke-dasharray: 100px;
    }

    100% {
      stroke-dashoffset: 0;
      stroke-dasharray: 57px;
    }
  }

  .drawline02-2 {
    animation: animate-drawline02-stroke-2 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
  }

  /***************************************************
   * Drawline03
   **************************************************/

  @keyframes animate-drawline03-stroke-1 {
    0% {
      stroke-dashoffset: 2353.85693359375px;
      stroke-dasharray: 2353.85693359375px;
    }

    100% {
      stroke-dashoffset: 4707.7138671875px;
      stroke-dasharray: 2353.85693359375px;
    }
  }

  .drawline03-1 {
    animation: animate-drawline03-stroke-1 3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s both;
  }

  @keyframes animate-drawline03-stroke-3 {
    0% {
      stroke-dashoffset: 1818px;
      stroke-dasharray: 100px;
    }

    100% {
      stroke-dashoffset: 1818px;
      stroke-dasharray: 70px;
    }
  }

  .drawline03-3 {
    animation: animate-drawline03-stroke-3 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.4s both;
  }

  @keyframes animate-drawline03-stroke-4 {
    0% {
      stroke-dashoffset: 946.999267578125px;
      stroke-dasharray: 946.999267578125px;
    }

    100% {
      stroke-dashoffset: 1893.99853515625px;
      stroke-dasharray: 946.999267578125px;
    }
  }

  .drawline03-4 {
    animation: animate-drawline03-stroke-4 2s cubic-bezier(0.215, 0.61, 0.355, 1) 0.6000000000000001s both;
  }

  @keyframes animate-drawline03-stroke-5 {
    0% {
      stroke-dashoffset: 1436.8779296875px;
      stroke-dasharray: 1436.8779296875px;
    }

    100% {
      stroke-dashoffset: 2873.755859375px;
      stroke-dasharray: 1436.8779296875px;
    }
  }

  .drawline03-5 {
    animation: animate-drawline03-stroke-5 4s cubic-bezier(0.215, 0.61, 0.355, 1) 0.8s both;
  }
}

.smart-ease {
  @include media-breakpoint-down(lg) {
    transform: none !important;
  }
}
